export const APP_PATHS = {
  enterprise: '/enterprise',
  helpandsupport: '/support/home/',
  myaccount: '/myaccount',
  auth: '/signin-cima',
  signout: '/signout',
  accountOverview: '/account/overview',
  applicationType: '/application-type/',
  root: '/',
};
