const authRedirectUrl =
  'https://qa.self-service.np.digital.business.comcast.com/oauth/oauth2';
const globalNavUrl =
  'https://bsd-global-nav-ui-qa.np.digital.business.comcast.com/globalNav.js';
const traceExporterUrl =
  'https://otlp-gateway.np.api-business.comcast.com:443/v1/traces';
const clientId = 'comcast-business-myaccount-qa6';

export default {
  authOptions: {
    authProvider: ['cima'],
    baseUrl: authRedirectUrl,
    clientId,
    passiveAuthTimeout: 10000,
    requireAuthentication: false,
    scope:
      'openid%20profile%20email%20address%20offline_access%20phone%20urn%3Abusiness-profileapi%20urn%3Abusiness-notificationapi',
  },
  globalNavConfig: {
    globalNavUrl,
  },
  observabilityConfig: {
    serviceName: 'global-nav-consumer',
    namespace: 'digital-ss',
    version: process.env.RELEASE_VERSION || 'unknownVersion',
    buildEnv: process.env.REACT_APP_ENV,
    language: 'javascript',
    exporterConfig: {
      url: traceExporterUrl,
    },
    handleFido: true,
  },
};
