import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from '@bsd/ui-auth';
import { setupProvider } from '@bsd/observability';

import { getConfig } from './config';

const { authOptions, globalNavConfig, observabilityConfig } = getConfig();

setupProvider(observabilityConfig);

const scriptTag = document.createElement('script');

scriptTag.src = globalNavConfig.globalNavUrl;
scriptTag.id = 'bsd-global-nav';
scriptTag.setAttribute('data-environment', process.env.REACT_APP_ENV);
scriptTag.setAttribute('data-application-root-path', '/');
scriptTag.setAttribute(
  'data-application-name',
  'BusinessServicesEcommerceExperience'
);
scriptTag.setAttribute('data-auth-type', 'CIMA');
scriptTag.setAttribute('data-page-category', 'PHONE');
scriptTag.setAttribute('data-page-name', 'VOICEMAIL');
scriptTag.setAttribute('data-enable-mega-menu', 'true');
scriptTag.setAttribute(
  'data-components',
  '[{ "type": "GLOBAL_HEADER", "elementId": "headerId"}, { "type": "GLOBAL_FOOTER", "elementId": "footerId"}]'
);
scriptTag.setAttribute('data-use-observability', 'true');

document.body.appendChild(scriptTag);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider options={authOptions}>
      <div className="bsd-navWrapper-details">
        <h1 className="bsd-heading">Global NAV Testing Platform</h1>
      </div>
      <App />
      <div
        data-tracking-parent
        data-tracking='{"containerName": "Global Nav Path Testing Platform", "temp":"Route Container"}'
      >
        <div className="routing">
          <h2>Route Simulation</h2>
          <div className="bsd-conatiner">
            <a
              className="bsd-nav-link bsd-nav-list-link"
              href="/enterprise/"
              data-tracking='{"linkName":"Global Nav Enterprise", "temp": "/enterprise/"}'
            >
              Enterprise
            </a>
            <a
              className="bsd-nav-link bsd-nav-list-link"
              href="/"
              data-tracking='{"linkName":"Global Nav ROOT", "temp": /"}'
            >
              Small Business
            </a>
            <a
              className="bsd-nav-link bsd-nav-list-link"
              href="/support/home/"
              data-tracking='{"linkName":"Help and Support", "temp": "/support/home/"}'
            >
              Help and Support
            </a>
            <a
              className="bsd-nav-link bsd-nav-list-link"
              href="/myaccount/"
              data-tracking='{"linkName":"My Account", "temp": "/myaccount/"}'
            >
              My Account
            </a>
            <a
              className="bsd-nav-link bsd-nav-list-link"
              href="/account/overview/"
              data-tracking='{"linkName":"Account overview", "temp": "/myaccount/"}'
            >
              Account - Overview
            </a>
            <h2>Application Type Simulation</h2>
            <a
              className="bsd-nav-link bsd-nav-list-link"
              href="/application-type/resource-url"
            >
              RESOURCE_URL
            </a>
            <a
              className="bsd-nav-link bsd-nav-list-link"
              href="/application-type/server"
            >
              SERVER
            </a>
            <a
              className="bsd-nav-link bsd-nav-list-link"
              href="/application-type/spa"
            >
              SPA
            </a>
          </div>
        </div>
        <h2>App Info</h2>
        <ul>
          <li>
            React app env:{' '}
            {process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : ''}
          </li>
          <li>Node env: {process.env.NODE_ENV ? process.env.NODE_ENV : ''}</li>
        </ul>
      </div>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
