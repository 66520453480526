import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import './App.css';
import routes from './routes';
import CimaAuth from './components/cimaAuth';
import { setAuthNav } from './utils/authUtils';

function App() {
  const [currentAccount, setCurrentAccount] = useState();
  const [currentAccountEvent, setCurrentAccountEvent] = useState();

  useEffect(() => {
    setAuthNav();
  });

  // TEMPORARY FUNCTIONALITY - this is to allow QA to test FLOW HEADER
  /* eslint-disable camelcase, no-console */
  const scriptData = document.querySelector('#bsd-global-nav');

  const customHandler = () => {
    console.log('**************CUSTOM ACTION IS CALLED********************');
  };

  useEffect(() => {
    window.addEventListener('bsd-nav-flow-header-click', customHandler);
    return () => {
      window.removeEventListener('bsd-nav-flow-header-click', customHandler);
    };
  }, []);
  // END OF TEMPORARY FUNCTIONALITY

  const switchAccountHandler = useCallback((data) => {
    const accountId = data?.detail?.accountGuid ?? data?.detail?.orderId;

    setCurrentAccount(accountId);
  }, []);

  const navRenderedHandler = useCallback((data) => {
    const eventAccount = data?.detail?.currentAccount;
    const accountId = eventAccount?.authGuid ?? eventAccount?.orderId;

    setCurrentAccountEvent(accountId);
  }, []);

  useEffect(() => {
    const localStorageAccount = JSON.parse(
      localStorage.getItem('currentAccount')
    );

    if (localStorageAccount) {
      const parsedAccount = JSON.parse(localStorageAccount?.value);
      const accountId = parsedAccount?.authGuid ?? parsedAccount?.orderId;

      setCurrentAccount(accountId);
    }
    document.addEventListener('Nav-Account-Switch', switchAccountHandler);
    document.addEventListener('Global-Nav-Rendered', navRenderedHandler);

    return () => {
      document.removeEventListener('Nav-Account-Switch', switchAccountHandler);
      document.removeEventListener('Global-Nav-Rendered', navRenderedHandler);
    };
  }, [navRenderedHandler, switchAccountHandler]);

  // istanbul ignore next'
  const renderApp = (route) => {
    const isEnterprise =
      route.path && route.path === '/enterprise' ? 'Enterprise' : 'SMB';

    let appName;

    switch (route.pageTitle) {
      case 'Enterprise': {
        appName = 'BusinessServicesEcommerceExperience';
        break;
      }
      case 'HelpAndSupport': {
        appName = 'HelpAndSupport';
        break;
      }
      case 'MyAccount': {
        appName = 'MyAccount';

        // TEMPORARY FUNCTIONALITY - this is to allow QA to test FLOW HEADER
        scriptData.dataset.components =
          '[{ "type": "FLOW_HEADER", "elementId": "headerId"}, { "type": "FLOW_FOOTER", "elementId": "footerId"}]';
        scriptData.dataset.flowHeader =
          '{"buttonText":"Close","customAction":"true"}';
        // END OF TEMPORARY FUNCTIONALITY

        break;
      }
      case 'RESOURCE_URL': {
        scriptData.dataset.applicationName = 'CustomerHub';
        scriptData.dataset.applicationType = 'RESOURCE_URL';
        scriptData.dataset.enableMegaMenu = 'false';

        const isQuery = window.location.search;

        if (isQuery) {
          scriptData.dataset.initialAccount = isQuery.substring(
            '?authGuid='.length,
            isQuery.length
          );
        }

        return (
          <div>
            <h2>Application Type - RESOURCE_URL</h2>
            <h3>Current Account in Local Storage</h3>
            <h4>
              {!currentAccount
                ? 'No local storage on initial load, this is populated from the script tag'
                : currentAccount}
            </h4>
            {isQuery && (
              <>
                <h3>Initial Account from Script Tag</h3>
                <h4>{scriptData.dataset.initialAccount}</h4>
                <h4>
                  Initial account value that is set from the script tag. If it
                  does not match BE call, it will automatically be switched to.
                </h4>
              </>
            )}
            {!isQuery && (
              <>
                <h4>
                  Add an authGuid as query string to test this app type. This
                  will be passed to the script tag as initial account.
                </h4>
                <h4>
                  Example:
                  /application-type/resource-url/?authGuid=SOME_AUTH_GUID
                </h4>
              </>
            )}
          </div>
        );
      }
      case 'SPA': {
        scriptData.dataset.applicationName = 'HelpAndSupport';
        scriptData.dataset.applicationType = 'SPA';
        scriptData.dataset.enableMegaMenu = 'false';

        return (
          <div>
            <h2>Application Type - SPA</h2>
            <h3>Current Account in Local Storage</h3>
            <h4>
              {!currentAccount
                ? 'No local storage on initial load, this is populated from the nav rendered event'
                : currentAccount}
            </h4>
            <h2>Account from Global Nav Rendered Event</h2>
            <h4>{currentAccountEvent}</h4>
            <h4>
              This is what the integrated app will get from the Nav Rendered
              Event
            </h4>
          </div>
        );
      }
      case 'SERVER': {
        appName = 'MyAccount';
        scriptData.dataset.applicationType = 'SERVER';
        scriptData.dataset.enableMegaMenu = 'false';

        return (
          <div>
            <h2>Application Type - SERVER</h2>
            <h3>Current Account in Local Storage</h3>
            <h4>{currentAccount}</h4>
            <h4>
              Local storage should always match selected account from BE call
            </h4>
          </div>
        );
      }
      default: {
        appName = 'BusinessServicesEcommerceExperience';
      }
    }

    scriptData.dataset.applicationName = appName;

    return (
      <div>
        <h2>Consumer App</h2>
        <h3>{isEnterprise}</h3>
      </div>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Switch>
            {routes.map((route) => {
              const { exact, path } = route;

              return (
                <Route
                  exact={exact}
                  key={path}
                  path={path}
                  component={() => renderApp(route)}
                />
              );
            })}
            <Route exact path="/signin-cima" component={CimaAuth} />
            <Route exact path="/" component={CimaAuth} />
          </Switch>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
