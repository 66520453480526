import PropTypes from 'prop-types';
import { useAuthContext } from '@bsd/ui-auth';

function Cima() {
  const { login, logout } = useAuthContext();

  const loginHandler = () => {
    login();
  };

  const logoutHandler = () => {
    logout();
    location.reload();
  };

  return (
    <div className="bsd-navWrapper-details">
      <div>CIMA Authorization/Authentication</div>
      <div className="bsd-button-containers">
        <input
          onClick={loginHandler}
          className="bsd-button"
          type="submit"
          value="Sign In"
        />
        <input
          onClick={logoutHandler}
          className="bsd-button"
          type="submit"
          value="Sign Out"
        />
      </div>
    </div>
  );
}

Cima.propTypes = {
  history: PropTypes.object,
};

export default Cima;
