import defaultConfig from './default';
import qaConfig from './qa';
import pqaConfig from './pqa';
import intConfig from './int';
import stgConfig from './stg';
import prodConfig from './prod';

const currentEnvironment = () => {
  const environment = process.env.REACT_APP_ENV || '';

  switch (environment) {
    case 'prod':
    case 'prod-va':
      return 'prod-va';
    case 'stg':
    case 'stg-va':
    case 'stage':
      return 'stg-va';
    case 'local':
    case 'dev':
    case 'qa':
    case 'pqa':
    case 'int':
    case 'prod-or':
    case 'stg-or':
      return environment;
    default: {
      console.warn(
        `Invalid environment passed from script tag! Expected valid env, received ${environment}`
      );
      return process.env.NODE_ENV;
    }
  }
};

export const getConfig = () => {
  const env = currentEnvironment();

  switch (env) {
    case 'int':
      return intConfig;
    case 'qa':
      return qaConfig;
    case 'pqa':
      return pqaConfig;
    case 'stg-va':
    case 'stg-or':
      return stgConfig;
    case 'prod-va':
    case 'prod-or':
      return prodConfig;
    default:
      return defaultConfig;
  }
};

export default getConfig();
