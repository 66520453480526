import { APP_PATHS } from './constants';
import App from '../App';

const routes = [
  {
    component: App,
    exact: false,
    pageTitle: 'Enterprise',
    path: APP_PATHS.enterprise,
  },
  {
    component: App,
    exact: false,
    pageTitle: 'HelpAndSupport',
    path: APP_PATHS.helpandsupport,
  },
  {
    component: App,
    exact: false,
    pageTitle: 'MyAccount',
    path: APP_PATHS.myaccount,
  },
  {
    component: App,
    exact: false,
    pageTitle: 'RESOURCE_URL',
    path: APP_PATHS.applicationType + 'resource-url',
  },
  {
    component: App,
    exact: false,
    pageTitle: 'SERVER',
    path: APP_PATHS.applicationType + 'server',
  },
  {
    component: App,
    exact: false,
    pageTitle: 'SPA',
    path: APP_PATHS.applicationType + 'spa',
  },
];

export default routes;
